* {
  margin: 0;
}

html {
  height: 100vh;
  width: 100vw;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) { display: none; } 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

._39IQj {
  padding: 20px 20px 20px 20px;
  background-image: url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png');
  background-size: cover;
  position: relative;
  overflow: auto;
  max-height: 382px;
}

._1mXIm {
  padding: 7px 14px 6px;
  background-color: rgb(255, 255, 255);
  border-radius: 0px 8px 8px;
  position: relative;
  transition: all 0.3s ease 0s;
  transform-origin: center top;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
  margin-top: 4px;
  max-width: calc(100% - 120px);
}

._3sxfE {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.4);
}

._1iYf5 {
  font-size: 14px;
  line-height: 19px;
  margin-top: 4px;
  color: rgb(17, 17, 17);
  white-space: pre-wrap;
}

._3Oyio {
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 0;
  border-color: transparent #ffffff transparent transparent;
  position: absolute;
  left: -10px;
  top: 0;
}

._3--Dl {
  display: flex;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(17, 17, 17, 0.5);
  justify-content: flex-end;
}

._2uc4w {
  background-color: rgb(9, 94, 84);
  display: flex;
}

._lI8mw {
  color: #f0ffffa1;
  cursor: pointer;
  font-size: 1.5em;
  position: absolute;
  right: 10px;
  top: 10px;
}

._3jDIS {
  align-items: center;
  align-self: center;
  background: #fff;
  border-radius: 50%;
  color: cadetblue;
  display: flex;
  font-size: 2em;
  height: 45px;
  margin: 5px;
  justify-content: center;
  padding: 10px;
  width: 45px;
}

._3trGj {
  color: rgb(255, 255, 255);
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

._3XZUa {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  display: block;
}

._Muogh {
  font-size: 13px;
  line-height: 18px;
  margin-top: 4px;
}

._3XP0H {
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

._siqLL {
  border-radius: 20px;
  padding: 12px 12px;
  border: none;
  margin-bottom: 10px;
  background-color: #fefefe;
}

._3twZq {
  padding: 6px 12px;
  border-radius: 20px;
  border: none;
  background: rgb(79, 206, 93);
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
  margin: 0 20px;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 1px;
}

._2qp0Z {
  align-items: center;
  align-self: flex-end;
  background-color: rgb(255, 255, 255);
  border-radius: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 12px;
  color: rgb(79, 206, 93);
  cursor: pointer;
  display: flex;
  font-size: 2em;
  height: 64px;
  justify-content: center;
  outline: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 64px;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  position: fixed;
  bottom: 100px;
  right: 0px;
  transition: 0.2s ease;

}

._2qp0Z:hover {
  background-color: #5ad167;
}

._2qp0Z:hover svg {
  fill: #fff;
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (max-width: 480px) {
  ._2qp0Z {
    width: 48px;
    height: 48px;
    font-size: 1.5em;
  }
}

._1yCVn {
  width: 360px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 12px 24px 0px;
  flex-direction: column;
  border-radius: 15px;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  transform: translate3d(0px, 0px, 0px);
  touch-action: auto;
  display: none;
  position: fixed;
  bottom: 110px;
  right: 25px;
}

._1qse9 {
  display: flex;
  -webkit-animation: _2-Apl 250ms ease-in-out both;
  animation: _2-Apl 250ms ease-in-out both;
}

._--3fm {
  display: none;
}

@-webkit-keyframes _2-Apl {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes _2-Apl {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (max-width: 480px) {
  ._1yCVn {
    bottom: 85px;
    width: 270px;
  }
}